<template>
  <div class="overview">
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-row style="background: #ecedef; padding: 5px">
        <template v-for="(data, cindex) in role_usera">
          <v-col :key="cindex">
            <div
              :class="'card m-0 top_card card-custom gutter-b border-primary'"
              :key="cindex"
            >
              <div class="card-body p-0">
                <div
                  :class="'card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white'"
                >
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <span class="symbol-label">
                        <span :class="'svg-icon svg-icon-xl svg-icon-white'">
                          <inline-svg
                            :src="$assetURL('media/custom-svg/engineer.svg')"
                          />
                        </span>
                      </span>
                      <span
                        class="text-dark-75 font-weight-bold mt-2 text-capitalize"
                        style="margin-left: 50px"
                      >
                        <template v-if="data?.name"> {{ data?.name }}</template>
                        <template v-if="data?.text"> {{ data?.text }}</template>
                      </span>
                    </span>

                    <div class="d-flex flex-column text-right bTitle">
                      <!--    <a href="#"> -->
                      <template>
                        <span
                          :class="'qnt_val font-weight-bolder text-primary'"
                        >
                          <template
                            v-if="data?.user_count && data?.value != 'all'"
                          >
                            {{ data?.user_count }}</template
                          >
                          <template
                            v-else-if="
                              data?.company_status_count && data?.value != 'all'
                            "
                          >
                            {{ data?.company_status_count }}</template
                          >
                          <template v-else-if="data?.value == 'all'">
                            {{ data?.all_users }}</template
                          >
                          <template v-else> 0 </template>
                        </span>
                      </template>
                      <!--   </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
      <v-card-title class="headline grey lighten-4">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-17"
        >
          Active Users ({{ teamListData && teamListData.length }}/{{
            total_users
          }})
        </span>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-simple-table>
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <td class="font-size-13 text-uppercase font-weight-500">Name</td>
              <td class="font-size-13 text-uppercase font-weight-500">Role</td>
              <td class="font-size-13 text-uppercase font-weight-500">Email</td>
              <td class="font-size-13 text-uppercase font-weight-500">Phone</td>
              <!--   <td class="font-size-13 text-uppercase font-weight-500">Date</td> -->
            </tr>
          </thead>
          <tbody>
            <!--   @click="routeToDetail(row.id)" -->
            <tr
              v-for="(row, index) in teamListData"
              :key="index"
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
            >
              <td class="font-size-14 font-weight-500">
                <v-avatar size="35" color="cyan" class="text-white">
                  <template v-if="row?.profile_logo?.file">
                    <img :src="row?.profile_logo?.file?.url" alt="John" />
                  </template>
                  <template v-else>{{ getAvatarText(row.full_name) }}</template>
                </v-avatar>
                {{ row.full_name }}
              </td>
              <td class="font-size-13">{{ row?.role?.name }}</td>
              <td class="font-size-13">{{ row?.user_email }}</td>
              <td class="font-size-13">{{ row?.phone_number }}</td>
              <!--  <td class="font-size-13">{{ formatDate(row.added_at) }}</td> -->
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  name: "overview",
  mixins: [CommonMixin],

  data() {
    return {
      upgradeplan: 75,
      teamListData: [],
      total_users: 0,
      role_usera: [],
    };
  },
  props: {
    currentUser: {
      type: Object,
      default() {
        return new Object();
      },
    },
    companyData: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  methods: {
    routeToDetail(id) {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: { id: id },
        })
      );
    },
    getUsers() {
      const _this = this;
      let requestParams = {
        entity: 0,
        status: "active",
      };
      _this.$store
        .dispatch(QUERY, {
          url: "user-list",
          data: requestParams,
        })
        .then(({ data }) => {
          _this.total_users = data.all_users;
          _this.teamListData = data.rows;
          _this.role_usera = data.role_usera;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
