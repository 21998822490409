<template>
  <div class="overview">
    <v-row>
      <v-col md="3">
        <div class="border-right sidebar--detail px-2">
          <v-chip label color="green" class="text-white mt-2"> Active </v-chip>
          <div class="py-3">
            <!--begin: Pic-->
            <div class="flex-shrink-0 text-center py-5">
              <div class="py-5">
                <v-img
                  class="profile-image mx-auto"
                  width="150"
                  :lazy-src="appCompanyLogo"
                  :src="appCompanyLogo"
                >
                </v-img>
              </div>
              <!--begin::Title-->
              <div class="d-flex justify-content-center flex-wrap mt-3">
                <div class="d-flex">
                  <span class="text-dark-75 font-size-h3 font-weight-bold">{{
                    appCompanyName
                  }}</span>
                </div>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Pic-->

            <!--begin::Info-->
            <div class="flex-grow-1">
              <!--begin::Content-->
              <div class="mt-1">
                <table width="100%">
                  <tr>
                    <td class="py-1 px-0 font-weight-500" width="200">
                      Company Name
                    </td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Company Name</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><v-icon class="mr-2" small>mdi-home-city</v-icon
                          >{{ appCompanyName }}</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="200" class="py-1 px-0 font-weight-500">
                      Contact Name
                    </td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>User Name</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><i class="flaticon2-user mr-2 font-size-lg"></i
                          >{{ currentUser.user_name }}</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 px-0 font-weight-500">Email</td>
                    <td class="py-1">
                      <div class="">
                        <a
                          :content="`<span class=&quot;font-size-13&quot;>User Email</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          :href="'mailto:' + currentUser?.user_email"
                          class="text-dark-50 text-hover-primary font-weight-500 mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                          >{{ currentUser?.user_email }}</a
                        >
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="py-1 px-0 font-weight-500">Phone No.</td>
                    <td class="py-1">
                      <div class="">
                        <a
                          :content="`<span class=&quot;font-size-13&quot;>Phone Number</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          :href="'tel:' + currentUser?.phone_number"
                          class="text-dark-50 text-hover-primary font-weight-500 mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><i class="flaticon2-phone mr-2 font-size-lg"></i
                          >{{ currentUser?.phone_number }}</a
                        >
                      </div>
                    </td>
                  </tr>
                  <!--  <tr>
                    <td class="py-1 px-0 font-weight-500">Language</td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Language</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >English</span
                        >
                      </div>
                    </td>
                  </tr> -->
                  <tr>
                    <td class="py-1 px-0 font-weight-500">Active From</td>
                    <td class="py-1">
                      <div class="font-weight-700">
                        {{ formatDate(currentUser.added_at) }}
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td class="py-1 px-0 font-weight-500">Country</td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Country</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          > <template v-if="companyData.landmark">
                                  {{ companyData.landmark }}
                                </template>
                                <template v-else>
                                  Singapore
                                </template>
                                </span
                        >
                      </div>
                    </td>
                  </tr> -->

                  <tr>
                    <td valign="top" class="py-1 px-0 font-weight-500">
                      Address
                    </td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Address</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          ><!-- <v-icon small class="mr-2" color="grey"
                            >mdi-map</v-icon
                          > -->
                          <template v-if="companyData">
                            <span
                              v-if="companyData && companyData.company_name"
                            >
                              <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                            >
                            <p v-if="companyData && companyData.company_name">
                              {{ companyData.street_1 }},
                              <br v-if="companyData.street_2" />
                              <template v-if="companyData.street_2">
                                {{ companyData.street_2 }},
                              </template>
                              <br />
                              <template
                                v-if="companyData && companyData.landmark"
                              >
                                {{ companyData.landmark }}
                              </template>
                              <template
                                v-if="
                                  companyData &&
                                  companyData.zip_code != '000000'
                                "
                              >
                                {{ companyData.zip_code }}
                              </template>
                            </p>
                          </template>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th
                      colspan="2"
                      class="grey lighten-4 font-size-19 color-custom-blue py-1"
                    >
                      Application Information
                    </th>
                  </tr>
                  <tr>
                    <td class="py-1 px-0 font-weight-500" width="200">
                      Client Code
                    </td>
                    <td class="py-1">
                      <div class="">
                        <span
                          :content="`<span class=&quot;font-size-13&quot;>Client Code</span>`"
                          v-tippy="{ arrow: true, animation: 'fade' }"
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2 font-weight-700"
                          >FSM2024</span
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="200" class="py-1 px-0 font-weight-500">
                      Android App Url
                    </td>
                    <td class="py-1">
                      <div class="">
                        <!-- <span
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >no app available</span
                        > -->
                        <em class="text-muted">no app available</em>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 px-0 font-weight-500">IOS App Url</td>
                    <td class="py-1">
                      <div class="">
                        <!-- <span
                          class="mr-lg-8 mr-5 mb-lg-0 mb-2"
                          >no app available</span
                        > -->
                        <em class="text-muted">no app available</em>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Info-->
          </div>
        </div>
      </v-col>
      <v-col md="9">
        <v-card flat class="custom-grey-border remove-border-radius mt-4">
          <v-card-title class="headline grey lighten-4">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-17"
            >
              Current Plan</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <v-row>
              <v-col cols="12" md="6">
                <v-card outlined class="grey lighten-3 pa-5">
                  <div>
                    <div class="d-flex justify-content-between mb-1">
                      <div>
                        <v-chip
                          class="text-uppercase"
                          color="black"
                          text-color="white"
                          label
                        >
                          <span style="font-size: 15px; letter-spacing: 0.6px">
                            Bussiness Plan
                          </span>
                        </v-chip>
                      </div>
                      <div class="font-weight-bold">
                        <span class="text-h4">$</span
                        ><span class="text-h2 font-weight-bold">0</span>/month
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      <div class="font-weight-500">Payment Type :</div>
                      <!--  <v-chip
                              label
                              small
                              outlined
                              color="blue"
                              class="ml-3 text-white"
                            >
                    <span
                      style="font-size: 15px; letter-spacing: 0.6px"
                      class="text-uppercase"
                    >
                    
                    </span>
                  </v-chip> -->
                      <em class="text-muted ml-2">not applicable</em>
                    </div>
                    <div class="py-1 mt-3">
                      <v-icon color="green" size="20" class="mr-2"
                        >mdi-check-all</v-icon
                      >
                      <span class="font-weight-700"
                        >{{ teamListData && teamListData.length }}/{{
                          total_users
                        }}
                        Users</span
                      >
                    </div>
                    <div class="py-1">
                      <v-icon color="green" size="20" class="mr-2"
                        >mdi-check-all</v-icon
                      >
                      <span class="font-weight-500"
                        >Last payment -
                        <!-- <span class="font-weight-700">30 July 2023 (800 SGD)</span> -->
                        <em class="text-muted ml-2">not applicable</em>
                      </span>
                    </div>
                    <div class="py-1">
                      <v-icon color="green" size="20" class="mr-2"
                        >mdi-check-all</v-icon
                      >
                      <span class="font-weight-500"
                        >Next payment
                        <span class="red--text font-weight-700">*</span> -
                        <!--   <span class="font-weight-700">30 July 2024 </span> -->
                        <em class="text-muted ml-2">not applicable</em>
                      </span>
                    </div>
                    <!-- <div class="py-1">
                  <span class="font-weight-500 pl-7 d-inline-block">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eius, rem.
                  </span>
                </div> -->
                  </div>
                  <v-card-actions class="px-0">
                    <a
                      href="https://www.genicteams.com/"
                      style="color: white !important"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="custom-bold-button white--text"
                        color="cyan"
                      >
                        Contact Sales
                      </v-btn>
                    </a>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card elevation="0">
                  <div>
                    <div class="text-h6 mb-1">
                      Active until
                      <span class="font-weight-700 red--text">
                        01 Jan 2024</span
                      >
                    </div>
                    <div>
                      We will send you a notification upon subscription
                      expiration
                    </div>
                  </div>
                </v-card>
                <div class="pa-3 mt-5 orange lighten-4 rounded">
                  <div class="text-h6 mb-1">We need your attention!</div>
                  <div>Your plan requires update</div>
                </div>
                <div class="mt-5">
                  <div class="text-h6 mb-2">
                    <div class="d-flex justify-content-between mb-1">
                      <h6 class="">Remaining Days</h6>
                      <h6 class="">5 of 30 Days</h6>
                    </div>
                    <v-progress-linear
                      rounded
                      v-model="upgradeplan"
                      color="orange"
                      height="8"
                    ></v-progress-linear>
                  </div>
                  <span>6 days remaining until your plan requires update</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          flat
          class="custom-grey-border remove-border-radius mt-4"
          v-if="false"
        >
          <v-row style="background: #ecedef; padding: 5px">
            <template v-for="(data, cindex) in role_usera">
              <v-col :key="cindex">
                <div
                  :class="'card m-0 top_card card-custom gutter-b border-primary'"
                  :key="cindex"
                >
                  <div class="card-body p-0">
                    <div
                      :class="'card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white'"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                      >
                        <span
                          class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                        >
                          <span class="symbol-label">
                            <span
                              :class="'svg-icon svg-icon-xl svg-icon-white'"
                            >
                              <inline-svg
                                :src="
                                  $assetURL('media/custom-svg/engineer.svg')
                                "
                              />
                            </span>
                          </span>
                          <span
                            class="text-dark-75 font-weight-bold mt-2 text-capitalize"
                            style="margin-left: 50px"
                          >
                            <template v-if="data?.name">
                              {{ data?.name }}</template
                            >
                            <template v-if="data?.text">
                              {{ data?.text }}</template
                            >
                          </span>
                        </span>

                        <div class="d-flex flex-column text-right bTitle">
                          <!--    <a href="#"> -->
                          <template>
                            <span
                              :class="'qnt_val font-weight-bolder text-primary'"
                            >
                              <template
                                v-if="data?.user_count && data?.value != 'all'"
                              >
                                {{ data?.user_count }}</template
                              >
                              <template
                                v-if="
                                  data?.company_status_count &&
                                  data?.value != 'all'
                                "
                              >
                                {{ data?.company_status_count }}</template
                              >
                              <template v-if="data?.value == 'all'">
                                {{ data?.all_users }}</template
                              >
                            </span>
                          </template>
                          <!--   </a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>
          <v-card-title class="headline grey lighten-4" v-if="false">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-17"
            >
              Active Users ({{ teamListData && teamListData.length }})
            </span>
          </v-card-title>
          <v-card-text class="p-6 font-size-16" v-if="false">
            <v-simple-table>
              <thead>
                <tr style="background-color: #f3f6f9; color: #24326d">
                  <td class="font-size-13 text-uppercase font-weight-500">
                    Name
                  </td>
                  <td class="font-size-13 text-uppercase font-weight-500">
                    Role
                  </td>
                  <td class="font-size-13 text-uppercase font-weight-500">
                    Email
                  </td>
                  <td class="font-size-13 text-uppercase font-weight-500">
                    Phone
                  </td>
                  <!--   <td class="font-size-13 text-uppercase font-weight-500">Date</td> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in teamListData"
                  :key="index"
                  :class="{
                    'custom-border-top': index == 0,
                    'orange_lighten-5': index % 2 == 0,
                  }"
                  @click="routeToDetail(row.id)"
                >
                  <td class="font-size-14 font-weight-500">
                    <v-avatar size="35" color="cyan" class="text-white">
                      <template v-if="row?.profile_logo?.file">
                        <img :src="row?.profile_logo?.file?.url" alt="John" />
                      </template>
                      <template v-else>{{
                        getAvatarText(row.full_name)
                      }}</template>
                    </v-avatar>
                    {{ row.full_name }}
                  </td>
                  <td class="font-size-13">{{ row?.role?.name }}</td>
                  <td class="font-size-13">{{ row?.user_email }}</td>
                  <td class="font-size-13">{{ row?.phone_number }}</td>
                  <!--  <td class="font-size-13">{{ formatDate(row.added_at) }}</td> -->
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  name: "overview",
  mixins: [CommonMixin],

  data() {
    return {
      upgradeplan: 75,
      teamListData: [],
      total_users: 0,
      role_usera: [],
    };
  },
  props: {
    currentUser: {
      type: Object,
      default() {
        return new Object();
      },
    },
    companyData: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  methods: {
    routeToDetail(id) {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: { id: id },
        })
      );
    },
    getUsers() {
      const _this = this;
      let requestParams = {
        entity: 0,
        status: "active",
      };
      _this.$store
        .dispatch(QUERY, {
          url: "user-list",
          data: requestParams,
        })
        .then(({ data }) => {
          _this.total_users = data.all_users;
          _this.teamListData = data.rows;
          _this.role_usera = data.role_usera;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
