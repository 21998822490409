<template>
  <div class="billing-plan">
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-17"
        >
          Billing Address</span
        >
        <v-spacer></v-spacer>
        <!--  <v-btn
          rounded
          depressed
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          >Edit Address</v-btn
        > -->
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-row>
          <v-col cols="12" md="6">
            <table width="100%">
              <tr>
                <td class="py-1 font-weight-500" width="120">Company Name</td>
                <td class="py-1">{{ appCompanyName }}</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500">Billing Email</td>
                <td class="py-1">{{ currentUser?.user_email }}</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500">Phone No.</td>
                <td class="py-1">{{ currentUser?.phone_number }}</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500 d-flex">Billing Address</td>
                <td class="py-1">
                  <template v-if="companyData">
                    <span v-if="companyData && companyData.company_name">
                      <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                    >
                    <p v-if="companyData && companyData.company_name">
                      {{ companyData.street_1 }},
                      <br v-if="companyData.street_2" />
                      <template v-if="companyData.street_2">
                        {{ companyData.street_2 }},
                      </template>
                      <br />
                      <template v-if="companyData && companyData.landmark">
                        {{ companyData.landmark }}
                      </template>
                      <template
                        v-if="companyData && companyData.zip_code != '000000'"
                      >
                        {{ companyData.zip_code }}
                      </template>
                    </p>
                  </template>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" md="6" v-if="false">
            <table width="100%">
              <tr>
                <td class="py-1 font-weight-500" width="120">Phone No.</td>
                <td class="py-1">{{ currentUser?.phone_number }}</td>
              </tr>
              <!-- <tr>
                <td class="py-1 font-weight-500">Country</td>
                <td class="py-1">Singapore</td>
              </tr>
              <tr>
                <td class="py-1 font-weight-500">Postal Code</td>
                <td class="py-1">403130</td>
              </tr> -->
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-17"
        >
          Billing List</span
        >
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-simple-table>
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <td class="font-size-13 text-uppercase font-weight-500">Date</td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Invoice/Credit No.
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Payment Method
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Amountd
              </td>
              <td
                class="font-size-13 text-uppercase font-weight-500"
                style="width: 150px !important"
              >
                Action
              </td>
            </tr>
          </thead>
          <tbody>
            <!--  <tr
              v-for="(row, index) in billingListData"
              :key="index"
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
            >
              <td class="font-size-14 font-weight-500">{{ row.date }}</td>
              <td class="font-size-14 font-weight-500">
                <v-chip outlined color="cyan" label>
                  {{ row.invoice_no }}
                </v-chip>
              </td>
              <td class="font-size-14 font-weight-500">
                <img
                  width="50"
                  contain
                  :src="$assetURL(row.payment_method)"
                  alt="visa"
                />
              </td>
              <td class="font-size-14 font-weight-500">$ {{ row.amount }}</td>
              <td class="font-size-14 font-weight-500">
                <v-btn class="rounded" icon color="red">
                  <v-icon dark> mdi-file-pdf-box </v-icon>
                </v-btn>
              </td>
            </tr> -->
            <tr>
              <td :colspan="5" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no billing at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "billing-plan",
  props: {
    companyData: {
      type: Object,
      default: new Object(),
    },
    currentUser: {
      type: Object,
      default: new Object(),
    },
  },
  data() {
    return {
      billingListData: [
        {
          id: 1,
          invoice_no: "INV-001",
          payment_method: "media/logos/mastercard.png",
          amount: 150.0,
          date: "15/01/2024",
        },
        {
          id: 2,
          invoice_no: "INV-002",
          payment_method: "media/logos/visa.png",
          amount: 120.0,
          date: "15/01/2024",
        },
        {
          id: 3,
          invoice_no: "INV-003",
          payment_method: "media/logos/mastercard.png",
          amount: 150.0,
          date: "18/01/2024",
        },
        {
          id: 4,
          invoice_no: "INV-004",
          payment_method: "media/logos/visa.png",
          amount: 140.0,
          date: "17/01/2024",
        },
      ],
    };
  },
};
</script>
